import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  heading?: string | null
  eyebrow?: string | null
  subheading?: string | ReactNode | null
  variant?: 'DEFAULT' | 'MISSION'
  headingLevel?: 1 | 2
}

export const ArticleHeading = ({
  heading,
  eyebrow,
  subheading,
  variant,
  headingLevel = 1,
  ...props
}: Props): JSX.Element => {
  const Heading = `h${headingLevel}` as ElementType
  const Subheading = `h${headingLevel + 1}` as ElementType
  const styles = {
    container: css`
      margin-left: var(--margin);
      margin-right: var(--margin);
    `,
    eyebrow: css`
      font-family: var(--body);
      font-size: var(--fs-16);
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: ${colors.red};
      margin: 2em 0 1em;
      line-height: 1;
    `,
    heading: css`
      font-size: var(--fs-72);
      color: ${colors.gray10};
      margin: 0;
      line-height: 1;
      ${mq().s} {
        font-size: var(--fs-60);
      }
      ${!subheading &&
      css`
        margin-bottom: 0.75em;
        &:after {
          content: '';
          display: block;
          width: 1em;
          height: 2px;
          background: ${colors.red};
          margin: 0.5em 0;
        }
      `}
    `,
    missionHeading: css`
      text-transform: uppercase;
    `,
    subheading: css`
      font-family: var(--body);
      font-size: var(--fs-16);
      font-weight: 450;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: ${colors.gray65};
      margin: 1.25em 0 3em;
      line-height: 1;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      {eyebrow && <span css={styles.eyebrow}>{eyebrow}</span>}
      <Heading
        css={[
          styles.heading,
          variant === 'MISSION' && styles.missionHeading,
        ]}
      >
        {heading}
      </Heading>
      {subheading && (
        <Subheading css={styles.subheading}>{subheading}</Subheading>
      )}
    </div>
  )
}
